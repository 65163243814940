<template>
  <div>
    <EditButton
      :disabled="!$atividades.permissoes.includes(14)"
      @func="activeModal = true"
    ></EditButton>

    <SideBar
      title="Editar tipo de dispositivo"
      @hide="cancelar()"
      :active="activeModal"
      size="small"
      textSucces="Atualizar"
      icon="edit"
      :disabled="!$atividades.permissoes.includes(15)"
      :item="deviceType"
      @excluir="remove()"
      @status="changeStatus()"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <vs-input
              class="inputx w-full"
              label-placeholder="Nome"
              v-validate="'required'"
              name="nome"
              v-model="deviceType.nome"
            />
            <span class="text-danger text-sm" v-show="errors.has('nome')">{{
              $validators.empty
            }}</span>
          </vs-col>
          <vs-col
            vs-w="12"
            vs-type="flex"
          >
            <vs-checkbox
              class="inputx w-full py-5"
              color="success"
              v-model="deviceType.deConsumo"
            >
              <div class="font-bold">
                De Consumo?
              </div>
              <div class="small-font">
                As pragas não serão solicitadas para este tipo de dispositivo.
              </div>
            </vs-checkbox>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  props: {
    deviceType: { required: true }
  },

  components: { ...components },

  data() {
    return {
      activeModal: false,
      originalData: {}
    };
  },

  mounted() {
    this._beforeEditingCache = Object.assign({}, this.deviceType);
    this.originalData = this.deviceType;
  },

  methods: {
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.update();
        } else {
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },

    async update() {
      this.$vs.loading();
      try {
        const deviceType = await this.$http.put(
          `device-types/${this.deviceType.id}`,
          this.deviceType
        );

        const text = `Edição do tipo de dispositivo ${deviceType.id}`;
        await this.createLog(text, "atualizar");
        this.$vs.notify(this.$notify.Success);
        this.activeModal = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },

    async changeStatus() {
      this.$vs.loading();
      try {
        const requestData = { lixeira: !this.deviceType.lixeira };
        const deviceType = await this.$http.put(
          `device-types/${this.deviceType.id}`,
          requestData
        );

        const text = `Mudança no status do tipo de dispositivo ${deviceType.id}`;
        await this.createLog(text, "atualizar");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },

    async remove() {
      this.$vs.loading();
      try {
        await this.$http.delete(`device-types/${this.deviceType.id}`);

        const text = `Exclusão do tipo de dispositivo ${this.deviceType.id}`;
        await this.createLog(text, "excluir");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },

    async createLog(text, type) {
      let logData = {
        id_colaborador: localStorage.getItem("id"),
        funcao: type,
        setor: "comercial",
        ip: window.localStorage.getItem("ip"),
        texto: text
      };
      await this.$logger(logData);
    },

    cancelar() {
      Object.assign(this.deviceType, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.activeModal = false;
    }
  }
};
</script>

<style></style>
