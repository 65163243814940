<template>
  <div class="w-full">
    <vs-table
      maxHeight="68vh"
      noDataText=""
      search
      pagination
      max-items="10"
      :data="deviceTypes"
    >
      <template slot="header">
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <h4 style="color: #b4aa99">Tipos de Dispositivo</h4>
          </vs-col>
          <vs-col vs-w="6">
            <add-device-type @update="getDeviceTypes"></add-device-type>
          </vs-col>
        </vs-row>
      </template>

      <template slot="thead">
        <vs-th sort-key="nome">Nome</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :state="deviceType.lixeira == 1 ? 'danger' : ''"
          :key="index"
          v-for="(deviceType, index) in data"
        >
          <vs-td :data="deviceType.nome">{{ deviceType.nome }}</vs-td>
          <vs-td class="actions">
            <edit-device-type @update="getDeviceTypes" :deviceType="deviceType">
            </edit-device-type>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div v-if="deviceTypes.length == 0">
      <div class="con-colors">
        <ul>
          <li class="danger-box">
            <h2 class="p-5" style="color: white !important">
              Nenhum tipo de dispositivo cadstrado
            </h2>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import AddDeviceType from "./add.vue";
import EditDeviceType from "./edit.vue";

export default {
  components: {
    AddDeviceType,
    EditDeviceType,
  },

  data() {
    return {
      deviceTypes: [],
    };
  },

  async mounted() {
    await this.getDeviceTypes();
  },

  methods: {
    async getDeviceTypes() {
      this.$vs.loading();
      try {
        this.deviceTypes = await this.$http.get("device-types");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },
  },
};
</script>