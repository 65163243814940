<template>
  <div class="w-full">
    <SuccessButton
      :disabled="!$atividades.permissoes.includes(14)"
      class="mr-6"
      @func="activeModal = true"
    ></SuccessButton>
    <SideBar
      title="Cadastrar novo tipo de dispositivo"
      @hide="activeModal = false"
      size="small"
      :active="activeModal"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <vs-input
              class="inputx w-full"
              v-validate="'required'"
              name="nome"
              label-placeholder="Nome"
              v-model="deviceType.nome"
            />
            <span class="text-danger text-sm" v-show="errors.has('nome')">
              {{ $validators.empty }}
            </span>
          </vs-col>
          <vs-col vs-w="12" vs-type="flex">
            <vs-checkbox
              class="inputx w-full py-5"
              color="success"
              v-model="deviceType.deConsumo"
            >
              <div class="font-bold">
                De Consumo?
              </div>
              <div class="small-font">
                As pragas não serão solicitadas para este tipo de dispositivo.
              </div>
            </vs-checkbox>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  components: { ...components },

  data() {
    return {
      deviceType: {},
      activeModal: false
    };
  },

  methods: {
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.add();
        } else {
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },

    async add() {
      this.$vs.loading();
      try {
        const deviceType = await this.$http.post(
          "device-types",
          this.deviceType
        );
        await this.createLog(deviceType);
        this.$vs.notify(this.$notify.Success);
        this.activeModal = false;
        this.deviceType = {};
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$emit("update");
        this.$vs.loading.close();
      }
    },

    async createLog(deviceType) {
      let logData = {
        id_colaborador: localStorage.getItem("id"),
        funcao: "cadastrar",
        setor: "comercial",
        ip: window.localStorage.getItem("ip"),
        texto: "Cadastro do tipo dispositivo N°" + deviceType.id
      };
      await this.$logger(logData);
    }
  }
};
</script>

<style></style>
